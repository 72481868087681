@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined);
.appointments-list-quick-status-form .MuiFormControl-root {
  width: 120px;
  padding: 0px;
  min-height: 30px;
  height: auto;
}

.appointments-list-quick-status-form .MuiFormControl-root label {
  display: none;
}

.appointments-list-quick-status-form .MuiFormControl-root .MuiFilledInput-input {
  padding: 6px 0px 6px 6px;
}

.appointments-list-quick-status-form .MuiFormControl-root span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}

.appointments-list-quick-status-form .MuiFormControl-root svg {
  display: none;
}

.rdt_TableCell {
  padding: 4px !important;
}

.rdt_TableCol {
  padding: 4px !important;
}

